import React, { useEffect, useState } from "react";
import {
  ColorbookModal,
  HeroSection,
  MarketplaceSection,
  MetaCardCollectionSection,
  NewsLetterSection,
  NftSection,
  SmartWalletSection,
} from "../../components";

const Homepage = () => {
  const [warningOpen, setWarningOpen] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setWarningOpen(true);
    }, 3000);
  }, []);

  return (
    <main className="w-full relative">
      <HeroSection />
      <NftSection />
      <MarketplaceSection />
      <MetaCardCollectionSection />
      <SmartWalletSection />
      <NewsLetterSection />
      <ColorbookModal show={warningOpen} setShow={setWarningOpen} />
    </main>
  );
};

export default Homepage;
