import React, { useEffect, useRef } from "react";
import { motion } from "framer-motion";
import { ColorBookImg } from "../../../assets";

const ColorbookModal = ({ show, setShow }) => {
  const modalRef = useRef(null);

  useEffect(() => {
    if (show) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setShow(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [show]);

  const handleModalClose = () => {
    setShow(false);
  };
  const variants = {
    open: { opacity: 1, scale: 1, transition: { duration: 0.2 } },
    closed: { opacity: 0, scale: 0, transition: { duration: 0.2 } },
  };

  return (
    <div
      className={`fixed top-0 right-0 w-full h-full bg-[#00000067] z-[999999] place-items-center flex justify-center transition-all duration-500 overflow-auto ${
        show ? "flex" : "hidden"
      }`}
    >
      <motion.div
        animate={show ? "open" : "closed"}
        variants={variants}
        ref={modalRef}
        className="w-[90%] md:w-[890px] bg-success rounded-lg flex flex-col items-start justify-start h-[80vh] overflow-y-auto tab:h-fit tinyScroll"
      >
        <div className="flex flex-col items-center justify-start gap-8 w-full tab:flex-row px-4 pt-10">
          <div className="tab:flex-1 w-full flex flex-col items-start justify-start gap-6">
            <span className="text-base w-fit font-semibold text-dark md:text-lg bg-white rounded-full px-4 select-none">
              Digital copy Pay with Crypto
            </span>
            <h2 className="text-2xl text-start font-semibold text-white md:text-3xl">
              Order a Meta Coloring Book on amazon or Etsy
            </h2>
            <p className="text-base text-start font-medium text-white md:text-lg">
              Here's the deal: Sales support crypto-friendly areas lacking
              internet, enabling access to Web3 dApps. It's a win-win, bridging
              the digital divide!
            </p>
          </div>
          <div className="tab:flex-1 w-full">
            <img src={ColorBookImg} alt="Coloring-book-image" />
          </div>
        </div>
        <p className="w-full flex items-center justify-center py-3 bg-[#3C5826] rounded-br-xl rounded-bl-xl text-base font-semibold md:text-lg text-center">
          Hey, we might airdrop a gift for your purchase history with us.
        </p>
      </motion.div>
    </div>
  );
};

export default ColorbookModal;
