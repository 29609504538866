import {
  Collection1,
  Collection2,
  Collection3,
  Collection4,
  Collection5,
  ColorbookIcon,
  EarnApyIcon,
  EarnFeeIcon,
  Elephant1Img,
  Elephant2Img,
  Elephant3Img,
  Elephant4Img,
  HelpIcon,
  LightPaperIcon,
  MarketIcon,
  MintIcon,
  TreasuryIcon,
} from "../assets";
import { FaDiscord, FaTelegramPlane, FaTwitter } from "react-icons/fa";

export const exploreDropLink = [
  {
    icon: MintIcon,
    coinName: "Mint Metacard",
    to: "/",
  },
  {
    icon: MarketIcon,
    coinName: "Marketplace",
    to: "/",
  },
  {
    icon: ColorbookIcon,
    coinName: "Coloring Book ",
    to: "/",
  },
  {
    icon: TreasuryIcon,
    coinName: "DAO Staking Treasury",
    to: "/",
  },
];

export const statDropLink = [
  {
    icon: EarnApyIcon,
    coinName: "Earn APY",
    to: "/",
  },
  {
    icon: EarnFeeIcon,
    coinName: "Earn Fees",
    to: "/",
  },
];
export const assetsDropLink = [
  {
    icon: LightPaperIcon,
    coinName: "Light Paper",
    to: "/",
  },
  {
    icon: HelpIcon,
    coinName: "Help Center",
    to: "/",
  },
];

export const metaCollectionData = [
  {
    id: 1,
    title: "50/50 Metacards",
    desc: "50/50 Metacard is the leader of the pack and the rarest Meta in our collection. Holding this Metacard gives users access to whitelist spots on all future projects.",
    qtyValue: "2000",
    floorPrice: "0.250 BNB",
    apyValue: "50%/50%",
    pic: Collection1,
  },
  {
    id: 2,
    title: "50/50 Metacards",
    desc: "Armor Trunk Metacard is the second most valuable Meta in our collection and it bequeaths holders' preferences for whitelist spots on future projects after Leaders  of the pack.",
    qtyValue: "3000",
    floorPrice: "0.250 BNB",
    apyValue: "50%/50%",
    pic: Collection2,
  },
  {
    id: 3,
    title: "35/50 Metacards",
    desc: "35/50 Meta is the most numerous Metacard in our collection and opens holders to exclusive airdrops & access to yield staking.",
    qtyValue: "5000",
    floorPrice: "0.250 BNB",
    apyValue: "35%/50%",
    pic: Collection3,
  },
  {
    id: 4,
    title: "25/50 Metacards",
    desc: "War Trunk Metacards are veteran and holding this Metacard opens users to juicy discounts, exclusive airdrops & access to yield staking.",
    qtyValue: "1000",
    floorPrice: "0.250 BNB",
    apyValue: "25%/50%",
    pic: Collection4,
  },
  {
    id: 5,
    title: "10/50 Metacards",
    desc: "Wolrd War 2 Trunk Metacards  are first defenders and holding this Metacard opens users to juicy discounts, exclusive airdrops & access to yield staking.",
    qtyValue: "1000",
    floorPrice: "0.250 BNB",
    apyValue: "10%/50%",
    pic: Collection5,
  },
];

export const footerIcons = [
  {
    href: "https://twitter.com",
    icon: FaTwitter,
  },
  {
    href: "https://t.me/zkFarmersOfficialChannel ",
    icon: FaTelegramPlane,
  },
  {
    href: "https://discord.com",
    icon: FaDiscord,
  },
];

export const footerLinks = [
  {
    headingTitle: "Company",
    links: [
      {
        to: "/about",
        text: "About",
      },
      {
        to: "/blog",
        text: "Blog",
      },
      {
        to: "/careers",
        text: "Careers",
      },
      {
        to: "/buy",
        text: "Buy us coffee",
      },
    ],
  },
  {
    headingTitle: "Services",
    links: [
      {
        to: "/metacards",
        text: "Metacards",
      },
      {
        to: "/marketplace",
        text: "High-end Marketplace",
      },
      {
        to: "/dao_staking",
        text: "DAO Staking",
      },
      {
        to: "/coloring_book",
        text: "Coloring Book",
      },
      {
        to: "/trading_fees",
        text: "Trading Fees",
      },
    ],
  },
  {
    headingTitle: "Resources",
    links: [
      {
        to: "/mint",
        text: "Mint process",
      },
      {
        to: "/lightpaper",
        text: "Lightpaper",
      },
      {
        to: "/partners",
        text: "Partners",
      },
      {
        to: "/documentation",
        text: "Documentation",
      },
      {
        to: "/github",
        text: "Github",
      },
      {
        to: "/newsletter",
        text: "Newsletter",
      },
      {
        to: "/tutorials",
        text: "Tutorials",
      },
    ],
  },
];

export const smartWalletData = [
  {
    image: Elephant1Img,
    name: "Metacard Smart Wallets",
    review:
      "Our marketplace will be designed to leverage the advanced features of the ERC-6551 token standard. This standard provides NFTs with a new identity in the world of finance, enabling engaging performance and unlocking new possibilities for asset utilization and value creation.Trading fees shared with early backers and MetaCard holders that contributes to development to benefit from marketplace success (12000 Spots).",
  },
  {
    image: Elephant2Img,
    name: "Funding Support From The Crypto Friends & Family",
    review:
      "50% Locked Funds Compounding:Half of your funding is allocated to the DAO Staking Treasury Wallet, where it is locked and compounds over time. This strategy ensures the long-term sustainability and growth of the project. By leveraging defi staking, yield farming with others like Pancakeswap and Uniswap, we generate attractive APY returns that benefit WPH Metacard holders.",
  },
  {
    image: Elephant3Img,
    name: "50% High-End Marketplace Development:",
    review:
      "The remaining 50% of your funding is dedicated to bootstrapping our high-end marketplace. This includes assembling the right team, acquiring blockchain tools, and implementing robust security measures. Our goal is to launch a complete working beta product by Q1-Q3 2024.",
  },
  {
    image: Elephant4Img,
    name: "All Wallets Can Be Traced",
    review:
      "We prioritize transparency and accountability. Through the utilization of DeFi and other wallet watch protocols, all wallets involved in the project can be easily traced and audited. This provides assurance to our investors that their funds are being utilized as intended.",
  },
];
