import React, { useState } from "react";
import { metaCollectionData } from "../../../data";
import { motion } from "framer-motion";
import { CollectionWolf } from "../../../assets";
import CollectionModal from "../../Modals/CollectionModal/CollectionModal";
import SuccessModal from "../../Modals/SuccessModal/SuccessModal";

const MetaCardCollectionSection = () => {
  const [index, setIndex] = useState(null);
  const [showCollection, setShowCollection] = useState(false);
  const [success, setSuccess] = useState(false);

  const container = {
    hidden: { opacity: 1 },
    visible: {
      opacity: 1,
      transition: {
        delayChildren: 0.2,
        staggerChildren: 0.4,
        duration: 0.3,
        type: "spring",
      },
    },
  };

  const itemdisplay = {
    hidden: { opacity: 0, y: 60, scale: 0.5 },
    visible: {
      opacity: 1,
      y: 0,
      scale: 1,
      duration: 2,
      type: "spring",
    },
  };

  return (
    <section className="w-full overflow-hidden relative">
      <div className="container">
        <div className="flex flex-col items-center justify-center gap-5 pb-20">
          <motion.h2
            initial={{ opacity: 0, y: -20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ type: "spring", duration: 2 }}
            viewport={{ once: true }}
            className="text-lg text-center font-bold md:text-xl tab:text-2xl text-lemonColor"
          >
            WPH Metacard Collections
          </motion.h2>
          <motion.p
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ type: "spring", duration: 2 }}
            viewport={{ once: true }}
            className="text-base font-medium text-white text-center md:text-lg"
          >
            Wolfpackherd 5 distinctive metacard collections are designed to
            bridge the gap between different networks. As users engage with
            these collections and contribute to the platform's growth the
            metacards become even more valuable, allowing users to accumulate
            part time trading fees associated with the marketplace on specific
            EVMs with different levels of opportunity for the holders.
          </motion.p>
          <motion.div
            variants={container}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 w-full items-start justify-start gap-6"
          >
            {metaCollectionData.map((item) => (
              <motion.div
                variants={itemdisplay}
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                key={item.id}
                onClick={() => {
                  setIndex(item.id);
                  setShowCollection(true);
                }}
                className="w-full border border-[#ffffff82] p-5 rounded-[20px] backdrop-blur-[100px] bg-[#ffffff0d] flex flex-col items-start justify-start cursor-pointer"
              >
                <div className="w-full flex items-center justify-center">
                  <img
                    src={item.pic}
                    alt={item.title}
                    className="w-[290px] aspect-square object-contain"
                  />
                </div>
                <div className="flex flex-col items-start justify-start gap-2">
                  <h2 className="text-base text-center font-bold md:text-xl text-lemonColor">
                    {item.title}
                  </h2>
                  <p className="text-sm font-medium text-white">{item.desc}</p>
                  <p className="text-base md:text-lg font-normal">
                    Quantity:{" "}
                    <span className="font-semibold text-lemonColor">
                      {item.qtyValue}
                    </span>
                  </p>
                  <p className="text-base md:text-lg font-normal">
                    Floor price:{" "}
                    <span className="font-semibold text-lemonColor">
                      {item.floorPrice}
                    </span>
                  </p>
                  <p className="text-base md:text-lg font-normal">
                    Trading Fees:{" "}
                    <span className="font-semibold text-lemonColor">
                      {item.apyValue}
                    </span>
                  </p>
                </div>
              </motion.div>
            ))}
          </motion.div>
        </div>
      </div>

      <CollectionModal
        show={showCollection}
        setShow={setShowCollection}
        id={index}
        setSuccess={setSuccess}
      />

      <SuccessModal
        show={success}
        setShow={setSuccess}
        detail={
          <span className="text-center text-base font-normal">
            You have successfully purchased Metacard (1). Kindly wait <br /> for
            the transferred ownership to you
          </span>
        }
      />

      <div className="tab:absolute bottom-0 right-0 flex flex-col items-center justify-center">
        <p className="text-base font-medium md:text-xl text-white text-xenter">
          “Buy with BNB” <br />
          Tap on metacards
        </p>

        <motion.img
          initial={{ opacity: 0, y: 50, scale: 0.5 }}
          whileInView={{ opacity: 1, y: 0, scale: 1 }}
          transition={{ type: "spring", duration: 2 }}
          viewport={{ once: true }}
          src={CollectionWolf}
          alt="wolf"
        />
      </div>
    </section>
  );
};

export default MetaCardCollectionSection;
