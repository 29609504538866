import React, { useEffect, useRef, useState } from "react";
import { Avatar, ConnectIcon, Logo } from "../../assets";
import { FiChevronDown } from "react-icons/fi";
import { FaBars } from "react-icons/fa";
import { MdClose } from "react-icons/md";
import { assetsDropLink, exploreDropLink, statDropLink } from "../../data";
import DropDown from "./DropDown/DropDown";
import Button from "../Button/Button";
import { Link } from "react-router-dom";

const Header = () => {
  const [mobileNav, setMobileNav] = useState(false);
  const [exploreDrop, setExploreDrop] = useState(false);
  const [statDrop, setStatDrop] = useState(false);
  const [assetDrop, setAssetDrop] = useState(false);
  const modalRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setMobileNav(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <header className="w-full bg-[#ffffff0f] sticky top-0 z-40 backdrop-blur-[24px]">
      <div className="container relative">
        <div className="flex items-center justify-between w-full py-4">
          <Link
            to="/"
            onMouseOver={() => {
              setExploreDrop(false);
              setStatDrop(false);
              setAssetDrop(false);
            }}
            className="flex items-center justify-start"
          >
            <img src={Logo} alt="WolfPack_Herd_Logo" />
            <h2 className="text-lg md:text-xl font-bold text-white lg:text-2xl">
              WolfPack Herd
            </h2>
          </Link>
          <div className="hidden tab:flex items-center justify-end gap-10">
            <nav className="flex items-center justify-end gap-10">
              <div
                onMouseOver={() => {
                  setExploreDrop(true);
                  setStatDrop(false);
                  setAssetDrop(false);
                }}
                className="flex items-center justify-center gap-1 relative text-lg font-medium text-white cursor-pointer transition-all duration-300"
              >
                <span>Explore</span>
                <span>
                  <FiChevronDown
                    size={25}
                    className={`transition-all duration-300 ${
                      exploreDrop ? "rotate-180" : ""
                    }`}
                  />
                </span>
                <DropDown
                  show={exploreDrop}
                  setShow={setExploreDrop}
                  data={exploreDropLink}
                />
              </div>

              <div
                onMouseOver={() => {
                  setExploreDrop(false);
                  setStatDrop(true);
                  setAssetDrop(false);
                }}
                className="flex items-center justify-center gap-1 relative text-lg font-medium text-white cursor-pointer transition-all duration-300"
              >
                <span>Stats</span>
                <span>
                  <FiChevronDown
                    size={25}
                    className={`transition-all duration-300 ${
                      statDrop ? "rotate-180" : ""
                    }`}
                  />
                </span>
                <DropDown
                  show={statDrop}
                  setShow={setStatDrop}
                  data={statDropLink}
                />
              </div>

              <div
                onMouseOver={() => {
                  setExploreDrop(false);
                  setStatDrop(false);
                  setAssetDrop(true);
                }}
                className="flex items-center justify-center gap-1 relative text-lg font-medium text-white cursor-pointer transition-all duration-300"
              >
                <span>Asset</span>
                <span>
                  <FiChevronDown
                    size={25}
                    className={`transition-all duration-300 ${
                      assetDrop ? "rotate-180" : ""
                    }`}
                  />
                </span>
                <DropDown
                  show={assetDrop}
                  setShow={setAssetDrop}
                  data={assetsDropLink}
                />
              </div>
            </nav>
            <img
              src={Avatar}
              alt="user_avatar"
              className="w-10 h-10 object-cover"
            />
            <Button
              text={
                <span className="flex items-center gap-2">
                  <img src={ConnectIcon} alt="/" />
                  <span>Connect Wallet</span>
                </span>
              }
              className="w-fit px-4 bg-btnBg border-none text-dark hover:opacity-90"
            />
          </div>
          <span
            onClick={() => setMobileNav(true)}
            className="text-white tab:hidden"
          >
            {mobileNav === false && <FaBars size={30} />}
          </span>
        </div>
        <div className="bg-blurColor w-[50%] tab:w-[400px] h-[400px] rounded-[50%] blur-[180px] md:blur-[380px] p-8 absolute -top-20 -left-10 z-[-1]"></div>
      </div>

      {/*MOBILENAV */}
      <div
        className={`${
          mobileNav ? "left-0" : "-left-full"
        } tab:hidden flex flex-col h-screen bg-black/40 gap-7 absolute top-0 w-full z-40 transition-all duration-300`}
      >
        <div
          ref={modalRef}
          className={`flex flex-col h-full bg-bodyBg gap-7 absolute top-0 z-40 p-4 w-[250px] overflow-y-auto tinyScroll`}
        >
          <div className="w-full flex items-center justify-end">
            <span className="text-white" onClick={() => setMobileNav(false)}>
              <MdClose size={30} />
            </span>
          </div>
          <div className="flex items-start justify-start gap-10 flex-col w-full">
            <nav className="flex items-start justify-start gap-10 flex-col w-full">
              <div
                onClick={() => {
                  setExploreDrop((prev) => !prev);
                  setStatDrop(false);
                  setAssetDrop(false);
                }}
                className="flex flex-col items-start justify-center gap-1 relative text-lg font-medium text-white cursor-pointer transition-all duration-300 w-full"
              >
                <div className="flex items-center justify-start gap-1 w-full">
                  <span>Explore</span>
                  <span>
                    <FiChevronDown
                      size={25}
                      className={`transition-all duration-300 ${
                        exploreDrop ? "rotate-180" : ""
                      }`}
                    />
                  </span>
                </div>
                <DropDown
                  show={exploreDrop}
                  setShow={setExploreDrop}
                  data={exploreDropLink}
                />
              </div>
              <div
                onClick={() => {
                  setExploreDrop(false);
                  setStatDrop((prev) => !prev);
                  setAssetDrop(false);
                }}
                className="flex flex-col items-start justify-center gap-1 relative text-lg font-medium text-white cursor-pointer transition-all duration-300 w-full"
              >
                <div className="flex items-center justify-start gap-1 w-full">
                  <span>Stats</span>
                  <span>
                    <FiChevronDown
                      size={25}
                      className={`transition-all duration-300 ${
                        statDrop ? "rotate-180" : ""
                      }`}
                    />
                  </span>
                </div>
                <DropDown
                  show={statDrop}
                  setShow={setStatDrop}
                  data={statDropLink}
                />
              </div>
              <div
                onClick={() => {
                  setExploreDrop(false);
                  setStatDrop(false);
                  setAssetDrop((prev) => !prev);
                }}
                className="flex flex-col items-start justify-center gap-1 relative text-lg font-medium text-white cursor-pointer transition-all duration-300 w-full"
              >
                <div className="flex items-center justify-start gap-1 w-full">
                  <span>Asset</span>
                  <span>
                    <FiChevronDown
                      size={25}
                      className={`transition-all duration-300 ${
                        assetDrop ? "rotate-180" : ""
                      }`}
                    />
                  </span>
                </div>
                <DropDown
                  show={assetDrop}
                  setShow={setAssetDrop}
                  data={assetsDropLink}
                />
              </div>
            </nav>
            <img
              src={Avatar}
              alt="user_avatar"
              className="w-10 h-10 object-cover"
            />
            <Button
              text={
                <span className="flex items-center gap-2">
                  <img src={ConnectIcon} alt="/" />
                  <span>Connect Wallet</span>
                </span>
              }
              className="w-fit px-4 bg-btnBg border-none text-dark hover:opacity-90"
            />
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
