import React, { useEffect, useRef } from "react";
import Button from "../../Button/Button";
import { motion } from "framer-motion";
import {
  CelebrationIcon,
  DiscordIcon,
  FacebookIcon,
  TelegramIcon,
  TwitterIcon,
} from "../../../assets";
import { useNavigate, Link } from "react-router-dom";

const SuccessModal = ({ show, setShow, detail }) => {
  const modalRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (show) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setShow(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [show, setShow]);

  const handleModalClose = () => {
    setShow(false);
    navigate("/");
  };
  const variants = {
    open: { opacity: 1, scale: 1, transition: { duration: 0.2 } },
    closed: { opacity: 0, scale: 0, transition: { duration: 0.2 } },
  };

  const socialLinks = [
    {
      href: "https://discord.com/",
      img: DiscordIcon,
    },
    {
      href: "https://telegram.com/",
      img: TelegramIcon,
    },
    {
      href: "https://web.facebook.com/",
      img: FacebookIcon,
    },
    {
      href: "https://twitter.com/",
      img: TwitterIcon,
    },
  ];

  return (
    <div
      className={`fixed top-0 right-0 w-full h-full bg-[#00000067] z-[999999] place-items-center flex justify-center transition-all duration-500 overflow-auto ${
        show ? "flex" : "hidden"
      }`}
    >
      <motion.div
        ref={modalRef}
        animate={show ? "open" : "closed"}
        variants={variants}
        className="flex w-[90%] md:w-[536px] bg-dark2 rounded-lg text-white flex-col items-center tab:justify-center h-[90vh] overflow-y-auto tab:h-[500px] tinyScroll p-6 gap-6 absolute "
      >
        <div className="w-full flex items-center justify-center gap-4">
          <h3 className="text-lg font-medium text-[#F1F1F1] md:text-2xl">
            Complete your Order
          </h3>
          <img src={CelebrationIcon} alt="/" className="w-10 h-10" />
        </div>
        {detail}
        <div className="flex flex-col items-center justify-center w-full gap-2">
          <p>Share on:</p>
          <div className="flex items-center justify-center w-full gap-5">
            {socialLinks.map((item, index) => (
              <Link to={item.href} key={index}>
                <img src={item.img} alt="social_icons" />
              </Link>
            ))}
          </div>
        </div>
        <Button
          onClick={handleModalClose}
          text="Return to home page"
          className="bg-lemonColor text-bodyBg px-10 font-medium w-fit border-lemonColor"
        />
      </motion.div>
    </div>
  );
};

export default SuccessModal;
