import React from "react";
import { Link } from "react-router-dom";
import { MarketPlaceImg } from "../../../assets";
import { motion } from "framer-motion";

const MarketplaceSection = () => {
  return (
    <section className="w-full relative overflow-hidden">
      <div className="bg-[#8475C5] w-[50%] tab:w-[300px] h-[300px] rounded-[50%] p-8 blur-[180px] md:blur-[300px] absolute right-0"></div>
      <div className="container">
        <div className="w-full py-20 flex items-center justify-between flex-col tab:flex-row gap-16">
          <motion.div
            initial={{ opacity: 0, x: 90 }}
            whileInView={{ opacity: 1, x: 0 }}
            viewport={{ once: true }}
            transition={{ type: "spring", duration: 2 }}
            className="tab:flex-1 w-full flex items-start justify-start gap-5 flex-col"
          >
            <h2 className="text-base text-start font-bold text-white md:text-lg tab:text-xl">
              WolfPackHerd ! Is a Developing High-End Marketplace
            </h2>
            <p className="text-base font-normal text-white tab:hidden">
              A comprehensive high-end NFT-Metacard Marketplace that operates on
              multiple chains and collects trading fees. read more{" "}
            </p>
            <p className="text-base font-normal text-white hidden tab:block">
              A comprehensive high-end NFT-Metacard Marketplace that operates on
              multiple chains and collects trading fees. The fees are shared
              50/50 between Trunk Metacard holders and the DAO Staking Treasury
              Wallet, offering potential 25x returns when trading volume reaches
              $150,000,000. The DAO Staking Treasury Wallet offers a strategic
              APY earnings from trading fees & listed revenue's to the DAO
              Staking Treasury Wallet.
            </p>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, x: -90 }}
            whileInView={{ opacity: 1, x: 0 }}
            viewport={{ once: true }}
            transition={{ type: "spring", duration: 2 }}
            className="tab:flex-1 w-full flex flex-col items-center justify-center gap-4"
          >
            <div className="relative border border-lemonColor w-[80%] md:w-[310px] aspect-square p-2 rounded flex items-center justify-center">
              <img
                src={MarketPlaceImg}
                alt=" “CashMoney For All” In Thy Blockchain Thy Trust"
                className="absolute w-[300px] aspect-square object-contain -top-10"
              />
            </div>
            <Link to="/whitepaper">
              “CashMoney For All” In Thy Blockchain Thy Trust
            </Link>
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default MarketplaceSection;
