import React from "react";

const Button = ({ type, text, disabled, className, onClick }) => {
  return (
    <button
      type={type}
      disabled={disabled}
      onClick={onClick}
      className={`${className} text-base font-medium border outline-none cursor-pointer py-2 transition-all duration-300 rounded-[20px] flex items-center justify-center`}
    >
      {text}
    </button>
  );
};

export default Button;
