import React from "react";
import { BiUpArrowAlt } from "react-icons/bi";
import { Link } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";
import { Logo } from "../../assets";
import { footerIcons, footerLinks } from "../../data";

const Footer = () => {
  return (
    <footer className="w-full py-10 bg-success">
      <div className="container">
        <div className="flex flex-col gap-4 items-start justify-start w-full">
          <div className="w-full flex items-center justify-end">
            <ScrollLink
              to="home"
              spy={true}
              smooth={true}
              className="cursor-pointer border border-white w-16 h-16 flex items-center justify-center"
            >
              <BiUpArrowAlt size={30} />
            </ScrollLink>
          </div>
          <div className="flex lg:flex-row flex-col items-start justify-between gap-8 w-full">
            <div className="flex-[1.8] w-full flex flex-col gap-3 items-start justify-start">
              <Link to="/" className="flex items-center justify-start">
                <img src={Logo} alt="WolfPack_Herd_Logo" />
                <h2 className="text-lg md:text-xl font-bold text-white lg:text-2xl">
                  WolfPack Herd
                </h2>
              </Link>
              <p>
                Discover valuable digital arts with WolfPackHerd. Buy, sell,
                stop losses and earn more. We do the Defi for you!
                ”Blaaaaaaaaaaaaaaaaaaaa”
              </p>
              <div className="w-full flex flex-col items-start justify-start gap-3">
                <h2 className="text-base font-semibold text-white">
                  Community
                </h2>
                <div className="flex items-center justify-start flex-wrap lg:flex-nowrap gap-5">
                  {footerIcons.map((item, i) => (
                    <Link
                      to={item.href}
                      target="_blank"
                      key={i}
                      rel="noreferrer noopener"
                    >
                      <item.icon size={25} />
                    </Link>
                  ))}
                </div>
              </div>
            </div>
            <div className="w-full lg:flex-[2] grid grid-cols-2 gap-8 items-start lg:justify-between md:grid-cols-3">
              {footerLinks.map((item, i) => (
                <div
                  key={i}
                  className="w-full flex flex-col items-start justify-start gap-8"
                >
                  <h2 className="text-base font-bold text-white">
                    {item.headingTitle}
                  </h2>
                  <div className="flex flex-col gap-4 w-full">
                    {item.links.map((item, i) => (
                      <Link
                        key={i}
                        to={item.to}
                        target="_blank"
                        rel="noreferrer noopener"
                        className="text-white text-sm font-medium transition-all duration-300 hover:text-[#00BAC6]"
                      >
                        {item.text}
                      </Link>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="border-t border-white w-full py-6 pb-0 flex flex-col gap-5 md:flex-row items-center justify-between">
            <p className="text-sm font-medium text-white flex items-center gap-2">
              <span className="text-lg">&copy;</span>
              <span>2023 Wolfpackherd Network,inc. All rights reserved</span>
            </p>
            <div className="flex items-center gap-4">
              <Link
                to="/term"
                target="_blank"
                rel="noreferrer noopener"
                className="text-sm font-medium text-white cursor-pointer hover:text-[#00BAC6] transition-all duration-300"
              >
                Terms of use
              </Link>
              <Link
                href="/privacy"
                target="_blank"
                rel="noreferrer noopener"
                className="text-sm font-medium text-white cursor-pointer hover:text-[#00BAC6] transition-all duration-300"
              >
                Privacy policy
              </Link>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
