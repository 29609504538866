import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";

const DropDown = ({ show, setShow, data }) => {
  const [screen, setScreen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      const newScreen = screenWidth <= 714 ? false : true;
      setScreen(newScreen);
    };

    handleResize();

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const modalRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setShow(false);
      }
    };
    document.addEventListener(
      `${screen ? "click" : "mousedown"}`,
      handleClickOutside
    );
    return () => {
      document.removeEventListener(
        `${screen ? "click" : "mousedown"}`,
        handleClickOutside
      );
    };
  }, [show, setShow]);

  return (
    <div
      ref={modalRef}
      onMouseLeave={() => setShow(false)}
      className={`flex-col tab:bg-success items-start justify-start w-full tab:w-[296px] tab:absolute rounded-3xl tab:right-0 tab:top-12 transition-all duration-300 z-20 ${
        show ? "flex" : "hidden"
      }`}
    >
      {data.map((item) => (
        <Link
          key={item.coinName}
          to={item.coinName}
          onClick={() => setShow(false)}
          className="flex items-center justify-start w-full p-4 border-b border-white gap-4 last:border-none"
        >
          <img
            src={item.icon}
            alt={item.coinName}
            className="w-8 h-8 object-cover"
          />
          <h2>{item.coinName}</h2>
        </Link>
      ))}
    </div>
  );
};

export default DropDown;
