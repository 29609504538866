import React from "react";
import MainLayout from "./layouts/MainLayout/MainLayout";
import { Route, Routes } from "react-router-dom";
import Homepage from "./pages/Homepage/Homepage";

const App = () => {
  return (
    <MainLayout>
      <Routes>
        <Route path="/" element={<Homepage />} />
      </Routes>
    </MainLayout>
  );
};

export default App;
