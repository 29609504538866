import React, { useEffect, useState } from "react";
import Button from "../../Button/Button";
import { motion } from "framer-motion";
import { BiLeftArrow, BiRightArrow } from "react-icons/bi";
import { smartWalletData } from "../../../data";

const SmartWalletSection = () => {
  const transition = { type: "spring", duration: 3 };
  const [selected, setSelected] = useState(0);
  const tLength = smartWalletData.length;

  useEffect(() => {
    const interval = setInterval(() => {
      setSelected((prevIndex) => (prevIndex + 1) % tLength);
    }, 4000);

    return () => clearInterval(interval);
  }, [selected, tLength]);

  return (
    <section className="w-full bg-success text-white overflow-hidden">
      <div className="container">
        <div className="w-full flex items-center justify-between gap-6 flex-col py-20 tab:flex-row">
          <div className="tab:flex-1 w-full flex items-center justify-center">
            <span
              onClick={() =>
                selected === 0
                  ? setSelected(tLength - 1)
                  : setSelected((prev) => prev - 1)
              }
              className="cursor-pointer"
            >
              <BiLeftArrow size={20} />
            </span>
            <motion.img
              key={selected}
              initial={{ opacity: 0, x: 100 }}
              whileInView={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: -100 }}
              transition={transition}
              src={smartWalletData[selected].image}
              alt=""
            />
            <span
              onClick={() =>
                selected === tLength - 1
                  ? setSelected(0)
                  : setSelected((prev) => prev + 1)
              }
              className="cursor-pointer"
            >
              <BiRightArrow size={20} />
            </span>
          </div>
          <motion.div
            key={selected}
            initial={{ opacity: 0, x: -100 }}
            whileInView={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: 100 }}
            transition={transition}
            className="tab:flex-1 w-full flex flex-col items-start justify-start gap-5"
          >
            <motion.h2 className="text-lg font-medium md:text-xl text-white">
              {smartWalletData[selected].name}
            </motion.h2>
            <motion.p
              key={selected}
              initial={{ opacity: 0, x: -100 }}
              whileInView={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: 100 }}
              transition={transition}
              className="text-base font-medium text-white"
            >
              {smartWalletData[selected].review}
            </motion.p>
            <Button
              text="High-End Marketplace For Metacards"
              className="w-fit px-4 bg-btnBg border-none text-dark hover:opacity-90 font-semibold"
            />
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default SmartWalletSection;
