import React, { useEffect, useState } from 'react';
import Button from '../../Button/Button';
import {
  Circle1,
  Circle2,
  Circle3,
  Circle4,
  CustomerCareIcon,
  FaqIcon,
  HeroLeftImg,
  HeroMiddleImg,
  HeroRightImg,
  NftArt1,
  NftArt2,
  NftArt3,
  WolfHead,
} from '../../../assets';
import { FiPlus } from 'react-icons/fi';
import { motion } from 'framer-motion';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import 'swiper/css';

const HeroSection = () => {
  const [limit, setLimit] = useState(false);
  const [contact, setContact] = useState(false);

  useEffect(() => {
    const scrollingFixed = () => {
      if (window.scrollY >= 3400) {
        setLimit(true);
      } else {
        setLimit(false);
      }
    };
    window.addEventListener('scroll', scrollingFixed);

    return () => {
      window.removeEventListener('scroll', scrollingFixed);
    };
  }, []);

  return (
    <section id='home' className='w-full overflow-hidden lg:h-[90vh] relative'>
      <div className='container'>
        <div className='w-full flex items-center justify-center flex-col lg:flex-row'>
          <div className='lg:flex-[1.8] pt-10 tab:pt-40 flex flex-col w-full items-start justify-start gap-20'>
            <motion.h2
              initial={{ opacity: 0, x: 90 }}
              whileInView={{ opacity: 1, x: 0 }}
              viewport={{ once: true }}
              transition={{ type: 'spring', duration: 2 }}
              className='text-2xl text-start font-medium text-white md:text-3xl lg:text-[34px] lg:leading-10'
            >
              Coding Meta Staking Cards, <br className='hidden tab:block' />{' '}
              compatible with ERC-6551 on the WolfPackHerd High-end{' '}
              <br className='hidden tab:block' /> Marketplace!
            </motion.h2>
            <motion.div
              initial={{ opacity: 0, scale: 0.5 }}
              whileInView={{ opacity: 1, scale: 1 }}
              viewport={{ once: true }}
              transition={{ type: 'spring', duration: 2 }}
            >
              <a
                href='https://www.dextools.io/app/en/bnb/pair-explorer/0xe7702bb4658abcbe8f38cd3735e264d961edcbaf?t=1707249285925'
                target='_blank'
                rel='noreferrer'
                className='w-fit px-4 bg-btnBg border-none text-dark hover:opacity-90 font-semibold text-base font-medium border outline-none cursor-pointer py-2 transition-all duration-300 rounded-[20px] flex items-center justify-center'
              >
                {' '}
                Contribute to coding by Buying Sav{' '}
              </a>

              <h3 className='mt-4'>
                Token Address: 0xf460635623350ffb6415eb45b6d87b0e90c8620b
              </h3>
            </motion.div>
          </div>
          <div className='tab:flex-[2] relative flex items-center justify-center w-full'>
            <div className='overflow-hidden relative hidden lg:flex items-center justify-center w-full'>
              <img
                src={HeroLeftImg}
                alt='/'
                className='w-[250px] aspect-square object-contain absolute left-0 bottom-0'
              />
              <img
                src={HeroMiddleImg}
                alt='/'
                className='w-[300px] aspect-square object-contain relative z-[2]'
              />
              <img
                src={HeroRightImg}
                alt='/'
                className='w-[250px] aspect-square object-contain absolute right-0 bottom-0'
              />
            </div>
            <div className='w-full mt-10 lg:hidden flex items-center justify-center'>
              <Swiper
                centeredSlides={true}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                navigation={true}
                modules={[Autoplay]}
                className='w-full'
              >
                <SwiperSlide>
                  <div className='w-full flex items-center justify-center'>
                    <img
                      src={NftArt3}
                      alt='wolfpackherd nft'
                      className='w-[320px] aspect-square object-contain'
                    />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className='w-full flex items-center justify-center'>
                    <img
                      src={NftArt1}
                      alt='wolfpackherd nft'
                      className='w-[320px] aspect-square object-contain'
                    />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className='w-full flex items-center justify-center'>
                    <img
                      src={NftArt2}
                      alt='wolfpackherd nft'
                      className='w-[320px] aspect-square object-contain'
                    />
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </div>
      </div>

      <div
        className={`w-full flex items-end justify-end right-4 md:right-10 ${
          contact ? 'top-[60%]' : 'top-[85%]'
        }  ${limit ? 'relative' : 'fixed'} z-[20]`}
      >
        <div className='flex flex-col items-center justify-end gap-5'>
          <div
            className={`flex-col items-center justify-center gap-4 transition-all duration-300 ${
              contact ? 'flex' : 'hidden'
            }`}
          >
            <span
              onClick={() => setContact(false)}
              className={`${
                limit
                  ? 'w-[20px] h-[20px]'
                  : 'w-10 h-10 md:w-[60px] md:h-[60px]'
              } flex items-center justify-center rounded-full bg-black cursor-pointer transition-all duration-300 hover:animate-pulse`}
            >
              <img src={CustomerCareIcon} alt='/' className='w-8 h-8' />
            </span>
            <span
              onClick={() => setContact(false)}
              className={`${
                limit
                  ? 'w-[20px] h-[20px]'
                  : 'w-10 h-10 md:w-[60px] md:h-[60px]'
              } flex items-center justify-center rounded-full bg-black cursor-pointer transition-all duration-300 hover:animate-pulse`}
            >
              <img src={FaqIcon} alt='/' className='w-8 h-8' />
            </span>
          </div>
          <motion.span
            initial={{ opacity: 0, scale: 0.5 }}
            whileInView={{ opacity: 1, scale: 1 }}
            transition={{ type: 'spring', duration: 2 }}
            viewport={{ once: true }}
            onClick={() => setContact((prev) => !prev)}
            className={`${
              limit ? 'w-[20px] h-[20px]' : 'w-10 h-10 md:w-[60px] md:h-[60px]'
            } flex items-center justify-center rounded-full bg-lemonColor cursor-pointer transition-all duration-300 hover:animate-pulse text-dark ${
              contact ? 'animate-pulse' : 'animate-bounce'
            }`}
          >
            <FiPlus
              size={30}
              className={`transition-all duration-300 ${
                contact ? 'rotate-[45deg]' : 'rotate-0'
              }`}
            />
          </motion.span>
        </div>
      </div>

      <div className='relative flex justify-center items-center top-[-250px] left-[-10px] lg:top-[-500px] lg:left-[260px] w-[120%] tab:w-full p-5 h-[inherit]'>
        <div className='absolute'>
          <motion.img
            initial='hidden'
            animate='visible'
            variants={{
              hidden: {
                scale: 0.1,
                opacity: 0,
              },
              visible: {
                scale: 1,
                opacity: 1,
                rotate: 360,
                transition: {
                  type: 'spring',
                  opacity: {
                    delay: 1,
                  },
                  scale: {
                    delay: 1,
                    duration: 0.6,
                  },
                  rotate: {
                    repeat: Infinity,
                    duration: 10.4,
                    ease: 'linear',
                  },
                },
              },
            }}
            src={Circle1}
            alt='spinner'
            className='w-[65%] block m-auto'
          />
        </div>

        <div className='absolute'>
          <motion.img
            initial='hidden'
            animate='visible'
            variants={{
              hidden: {
                scale: 0.1,
                opacity: 0,
              },
              visible: {
                scale: 1,
                opacity: 1,
                rotate: -360,
                transition: {
                  type: 'spring',
                  opacity: {
                    delay: 1,
                  },
                  scale: {
                    delay: 1,
                    duration: 0.6,
                  },
                  rotate: {
                    repeat: Infinity,
                    duration: 10.4,
                    ease: 'linear',
                  },
                },
              },
            }}
            src={Circle2}
            alt='spinner'
            className='w-[85%] tab:w-[75%] block m-auto'
          />
        </div>

        <div className='absolute'>
          <motion.img
            initial='hidden'
            animate='visible'
            variants={{
              hidden: {
                scale: 0.1,
                opacity: 0,
              },
              visible: {
                scale: 1,
                opacity: 1,
                rotate: 360,
                transition: {
                  type: 'spring',
                  opacity: {
                    delay: 1,
                  },
                  scale: {
                    delay: 1,
                    duration: 0.6,
                  },
                  rotate: {
                    repeat: Infinity,
                    duration: 10.4,
                    ease: 'linear',
                  },
                },
              },
            }}
            src={Circle3}
            alt='spinner'
            className='w-[95%] tab:w-[75%] block m-auto'
          />
        </div>

        <div className='absolute'>
          <motion.img
            initial='hidden'
            animate='visible'
            variants={{
              hidden: {
                scale: 0.1,
                opacity: 0,
              },
              visible: {
                scale: 1,
                opacity: 1,
                rotate: -360,
                transition: {
                  type: 'spring',
                  opacity: {
                    delay: 1,
                  },
                  scale: {
                    delay: 1,
                    duration: 0.6,
                  },
                  rotate: {
                    repeat: Infinity,
                    duration: 10.4,
                    ease: 'linear',
                  },
                },
              },
            }}
            src={Circle4}
            alt='spinner'
            className='w-full tab:w-[80%] block m-auto'
          />
        </div>
      </div>
      <motion.img
        initial={{ opacity: 0, y: 50, scale: 0.5 }}
        whileInView={{ opacity: 1, y: 0, scale: 1 }}
        transition={{ type: 'spring', duration: 2 }}
        viewport={{ once: true }}
        src={WolfHead}
        alt='wolf'
        className='tab:absolute bottom-0 right-60'
      />
    </section>
  );
};

export default HeroSection;
