import React, { useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";
import { MdClose } from "react-icons/md";
import { metaCollectionData } from "../../../data";
import { FiChevronDown, FiCopy } from "react-icons/fi";
import Button from "../../Button/Button";
import { BsExclamationCircleFill } from "react-icons/bs";

const CollectionModal = ({ show, setShow, id, setSuccess }) => {
  const choosenData = metaCollectionData.find((item) => item.id === id);
  const modalRef = useRef(null);
  const [usdRate, setUsdRate] = useState("0.015BNB");
  const [insufficent, setInsufficent] = useState(false);
  const [sufficent, setSufficent] = useState(false);
  const [mainModal, setMainModal] = useState(true);

  useEffect(() => {
    if (show) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setShow(false);
        setInsufficent(false);
        setSufficent(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [show]);

  const handleModalClose = () => {
    setShow(false);
  };
  const variants = {
    open: { opacity: 1, scale: 1, transition: { duration: 0.2 } },
    closed: { opacity: 0, scale: 0, transition: { duration: 0.2 } },
  };

  const total = parseFloat("0.014BNB") + parseFloat(choosenData?.floorPrice);

  return (
    <>
      <div
        className={`fixed top-0 right-0 w-full h-full bg-[#00000067] z-[999999] place-items-center flex justify-center transition-all duration-500 overflow-auto ${
          show ? "flex" : "hidden"
        }`}
      >
        <div className="w-fit" ref={modalRef}>
          <motion.div
            animate={mainModal ? "open" : "closed"}
            variants={variants}
            className={`w-[90%] md:w-[536px] bg-dark2 rounded-lg text-white flex-col items-start justify-start h-[90vh] overflow-y-auto tab:h-fit tinyScroll p-6 gap-6 ${
              mainModal ? "flex" : "hidden"
            }`}
          >
            <div className="w-full flex items-center justify-between gap-4">
              <h3 className="text-lg font-medium text-[#F1F1F1] md:text-2xl">
                Complete your payment
              </h3>
              <span onClick={handleModalClose} className="cursor-pointer">
                <MdClose size={25} />
              </span>
            </div>
            <div className="w-full flex flex-col items-start justify-start gap-4 md:flex-row">
              <img
                src={choosenData?.pic}
                alt={choosenData?.title}
                className="w-[80px] aspect-square rounded-lg object-cover"
              />
              <div className="flex flex-col items-start">
                <h2 className="text-base font-bold text-white">
                  {choosenData?.title}
                </h2>
                <p className="text-sm font-medium text-[#C6C6C6]">
                  WPF Metacard Collections
                </p>
              </div>
            </div>
            <div className="w-full flex flex-col items-start justify-start gap-5">
              <h4 className="text-sm font-normal text-[#C6C6C6]">
                Order summary:
              </h4>
              <div className="w-full flex items-center justify-between gap-5">
                <h5 className="text-sm font-normal text-[#C6C6C6]">Price:</h5>
                <span className="text-base font-medium text-white">
                  {choosenData?.floorPrice}
                </span>
              </div>
              <div className="w-full flex items-center justify-between gap-5">
                <h5 className="text-sm font-normal text-[#C6C6C6]">
                  Network fee:
                </h5>
                <span className="text-base font-medium text-white">
                  0.014BNB
                </span>
              </div>
              <div className="w-full flex items-center justify-between gap-5">
                <h5 className="text-sm font-normal text-[#C6C6C6]">
                  Total price:
                </h5>
                <span className="text-base font-medium text-white">
                  {total}BNB
                </span>
              </div>
              <div className="w-full flex items-center justify-between gap-5">
                <h5 className="text-sm font-normal text-[#C6C6C6] flex items-center justify-start gap-2">
                  <span>Exchange in</span>

                  <span className="flex items-center justify-center gap-1 text-white cursor-pointer">
                    <span>USD</span>
                    <FiChevronDown size={20} />
                  </span>
                </h5>
                <span className="text-base font-medium text-white">
                  {usdRate}
                </span>
              </div>
            </div>
            <div className="w-full flex items-center justify-center mt-5">
              <Button
                onClick={() => {
                  if (usdRate === "0.014BNB") {
                    setInsufficent(true);
                    setMainModal(false);
                  } else {
                    setSufficent(true);
                    setMainModal(false);
                  }
                }}
                text="Continue"
                className="bg-lemonColor text-bodyBg px-10 font-medium w-[300px] border-lemonColor"
              />
            </div>
          </motion.div>

          {/* INSUFFICENT BALANCE */}
          <motion.div
            animate={insufficent ? "open" : "closed"}
            variants={variants}
            className={`w-[90%] md:w-[536px] bg-dark2 rounded-lg text-white flex-col items-center tab:justify-center h-[90vh] overflow-y-auto tab:h-[500px] tinyScroll p-6 gap-6 ${
              insufficent ? "flex" : "hidden"
            }`}
          >
            <div className="w-full flex items-center justify-center gap-4">
              <h3 className="text-lg font-medium text-[#F1F1F1] md:text-2xl">
                Complete your payment
              </h3>
            </div>
            <div className="flex items-center justify-center gap-10">
              <div className="flex items-center justify-center gap-3">
                <span className="text-[#C60000]">
                  <BsExclamationCircleFill size={20} />
                </span>
                <span className="text-sm font-medium text-[#C6C6C6]">
                  Insufficient funds
                </span>
              </div>
              <div className="flex items-start justify-start flex-col gap-2">
                <h6 className="text-sm font-normal text-[#C6C6C6]">Balance:</h6>
                <h4 className="text-lemonColor text-base font-medium bg-black w-fit px-3 py-1 rounded-full">
                  0.00BUSD
                </h4>
              </div>
            </div>
            <div className="w-full tab:w-[320px] mx-auto">
              <p className="text-sm font-medium text-white">
                You need <span className="text-lemonColor">3.611BNB</span> to
                complete this transaction. Kindly{" "}
                <span className="text-lemonColor">transfer funds </span> to your
                wallet
              </p>
            </div>
            <div className="w-full flex flex-col items-start justify-start gap-3">
              <label
                htmlFor="wallet"
                className="text-sm font-normal text-[#C6C6C6]"
              >
                Wallet address
              </label>
              <div className="flex items-center justify-between gap-4 bg-bodyBg h-12 w-full px-4 rounded-full">
                <input
                  type="text"
                  name="wallet"
                  id="wallet"
                  className="w-full h-full bg-transparent border-none text-white placeholder:text-[#737373] text-sm outline-none font-medium"
                  placeholder="0xc8edd5cb616150e76cc169049b76c36a56782bac"
                />
                <span className="cursor-pointer">
                  <FiCopy size={20} />
                </span>
              </div>

              <div className="w-full flex items-center justify-center mt-5">
                <Button
                  disabled
                  text="Continue"
                  className="bg-white text-bodyBg px-10 font-medium w-[300px] border-white disabled:opacity-40 disabled:cursor-not-allowed"
                />
              </div>
            </div>
          </motion.div>

          {/* SUFFICENT BALANCE */}
          <motion.div
            animate={sufficent ? "open" : "closed"}
            variants={variants}
            className={`w-[90%] md:w-[536px] bg-dark2 rounded-lg text-white flex-col items-center tab:justify-center h-[90vh] overflow-y-auto tab:h-[500px] tinyScroll p-6 gap-6 ${
              sufficent ? "flex" : "hidden"
            }`}
          >
            <div className="w-full flex items-center justify-center gap-4">
              <h3 className="text-lg font-medium text-[#F1F1F1] md:text-2xl">
                Complete your Order
              </h3>
            </div>
            <div className="flex flex-col items-start justify-start w-full gap-3">
              <label
                htmlFor="book-digital"
                className="text-sm font-normal text-[#C6C6C6]"
              >
                1x Coloring Book-Digital
              </label>

              <input
                type="text"
                name="book-digital"
                id="book-digital"
                value="No Cost: 0.00 BNB"
                onChange={() => {}}
                className="bg-bodyBg h-12 w-full px-4 rounded-full border-none text-lemonColor placeholder:text-[#737373] text-base outline-none font-medium"
                placeholder="1x Coloring Book-Digital"
              />
            </div>

            <h5 className="text-base font-medium text-lemonColor">
              Free Ethermail Click Here
            </h5>
            <div className="flex flex-col items-start justify-start w-full gap-3">
              <label
                htmlFor="mail"
                className="text-sm font-normal text-[#C6C6C6]"
              >
                Ethermail Address
              </label>

              <input
                type="text"
                name="mail"
                id="mail"
                className="w-full  bg-bodyBg h-12 px-4 rounded-full border-none text-white placeholder:text-[#737373] text-sm outline-none font-medium"
                placeholder="Email:"
              />
            </div>
            <Button
              onClick={() => {
                setSuccess(true);
                setShow(false);
              }}
              text="Continue"
              className="bg-lemonColor text-bodyBg px-10 font-medium w-[300px] border-lemonColor"
            />
          </motion.div>
        </div>
      </div>
    </>
  );
};

export default CollectionModal;
