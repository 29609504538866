import React from "react";
import {
  CollectionWolf,
  Partner1Img,
  Partner2Img,
  Partner3Img,
  Partner4Img,
  Partner5Img,
  Partner6Img,
  Partner7Img,
  Partner8Img,
} from "../../../assets";
import Button from "../../Button/Button";
import { motion } from "framer-motion";

const NewsLetterSection = () => {
  const partnerIcons = [
    Partner1Img,
    Partner5Img,
    Partner2Img,
    Partner3Img,
    Partner4Img,
    Partner8Img,
    Partner6Img,
    Partner7Img,
  ];

  const container = {
    hidden: { opacity: 1 },
    visible: {
      opacity: 1,
      transition: {
        delayChildren: 0.2,
        staggerChildren: 0.4,
        duration: 0.3,
        type: "spring",
      },
    },
  };

  const itemdisplay = {
    hidden: { opacity: 0, scale: 0.5 },
    visible: {
      opacity: 1,
      y: 0,
      scale: 1,
      duration: 2,
      type: "spring",
    },
  };

  return (
    <section className="py-20 w-full bg-bodyBg relative overflow-hidden">
      <div className="container">
        <div className="flex flex-col items-center justify-center gap-6">
          <motion.h2
            initial={{ opacity: 0, y: -20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ type: "spring", duration: 2 }}
            viewport={{ once: true }}
            className="text-base font-medium md:text-lg"
          >
            Our Connections
          </motion.h2>
          <motion.div
            variants={container}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            className="w-full flex-wrap flex items-center justify-center gap-6"
          >
            {partnerIcons.map((item, index) => (
              <motion.img
                variants={itemdisplay}
                initial="hidden"
                whileInView="visible"
                src={item}
                alt="clients"
                key={index}
                className="w-[25%] md:w-[163px] aspect-video object-contain rounded-md"
              />
            ))}
          </motion.div>
          <div className="bg-[#ffffff0f] w-full p-6 lg:p-16 flex items-center justify-between gap-5 flex-col lg:flex-row rounded-[40px] relative z-[2]">
            <motion.div
              initial={{ opacity: 0, x: 90 }}
              whileInView={{ opacity: 1, x: 0 }}
              viewport={{ once: true }}
              transition={{ type: "spring", duration: 2 }}
              className="lg:flex-[0.6] w-full flex items-start justify-start flex-col gap-8"
            >
              <h2 className="text-base font-bold text-white md:text-2xl">
                Newsletters
              </h2>
              <p className="text-sm md:text-base font-medium text-white">
                Don’t miss Wolfpackherd updates! Join our mailing list to stay
                in the loop with our newest feature.{" "}
              </p>
            </motion.div>
            <motion.div
              initial={{ opacity: 0, x: -90 }}
              whileInView={{ opacity: 1, x: 0 }}
              viewport={{ once: true }}
              transition={{ type: "spring", duration: 2 }}
              className="lg:flex-1 w-full flex flex-col items-start justify-start gap-20"
            >
              <input
                type="text"
                placeholder="Email"
                className="w-[80%] h-14 rounded-[20px] outline-none px-4 text-[#1c1c1c4f] placeholder:text-[#1c1c1c4f] font-bold text-base md:text-xl"
              />
              <Button
                text="Subscribe"
                className="w-fit px-6 py-2 bg-btnBg border-none text-dark hover:opacity-90 font-semibold tab:ml-5"
              />
            </motion.div>
          </div>
        </div>
      </div>
      <motion.img
        initial={{ opacity: 0, y: 50, scale: 0.5 }}
        whileInView={{ opacity: 1, y: 0, scale: 1 }}
        transition={{ type: "spring", duration: 2 }}
        viewport={{ once: true }}
        src={CollectionWolf}
        alt="wolf"
        className="tab:absolute bottom-0 right-40 flex flex-col items-center justify-center z-40"
      />
      <div className="w-full flex items-center justify-center">
        <div className="bg-blurColor w-[50%] tab:w-[200px] h-[200px] rounded-[50%] p-8 absolute blur-[180px] md:blur-[580px]"></div>
      </div>
    </section>
  );
};

export default NewsLetterSection;
