import React, { useEffect, useState } from "react";
import {
  NftArt1,
  NftArt2,
  NftArt3,
  NftArt4,
  NftArt5,
  NftArt6,
} from "../../../assets";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, A11y } from "swiper/modules";
import "swiper/css";

const NftSection = () => {
  const [screen, setScreen] = useState(null);

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      const newScreen = screenWidth <= 714 ? 2 : screenWidth <= 940 ? 3 : 6;
      setScreen(newScreen);
    };

    handleResize();

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const nftsData = [
    NftArt1,
    NftArt2,
    NftArt3,
    NftArt4,
    NftArt4,
    NftArt5,
    NftArt6,
    NftArt1,
    NftArt2,
    NftArt3,
    NftArt4,
    NftArt4,
    NftArt5,
    NftArt6,
    NftArt1,
    NftArt2,
    NftArt3,
    NftArt4,
    NftArt4,
    NftArt5,
    NftArt6,
    NftArt1,
    NftArt2,
    NftArt3,
    NftArt4,
    NftArt4,
    NftArt5,
    NftArt6,
  ];

  return (
    <section className="w-full relative overflow-hidden">
      <div className="bg-blurColor w-[50%] tab:w-[200px] h-[200px] rounded-[50%] blur-[180px] md:blur-[580px] p-8 absolute -top-20 -left-10 z-[-1]"></div>
      <div className="container">
        <div className="w-full pt-4 pb-10">
          <Swiper
            slidesPerView={screen}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            navigation={true}
            modules={[Autoplay, A11y]}
            className="w-full"
          >
            {nftsData.map((item, index) => (
              <SwiperSlide key={index} className="w-full mx-2">
                <div className="w-full flex items-center justify-center bg-[#ffffff0d] rounded-lg h-[280px] border border-[#ffffff82]">
                  <img
                    src={item}
                    alt="wolfpackherd nft"
                    className="w-[180px] aspect-square object-contain hover:scale-105 transition-all duration-300 cursor-pointer"
                  />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </section>
  );
};

export default NftSection;
